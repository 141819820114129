export const DataProduct = [
  {
    id: 1,
    title: 'bawang 1',
    type : 'Tajuk (Thailand Nganjuk)',
    href: '/bawang1',
    image: '/tipeB2.jpeg',
    harvest: 'Januari',
    price : '50.000 - 60.000',
    description: 'bawang 1 bawang 1 bawang 1 bawang 1 bawang 1 bawang 1 bawang 1 bawang 1'
  },
  {
    id: 2,
    title: 'bawang 2',
    type : 'Tajuk (Thailand Nganjuk)',
    href: '/bawang2',
    image: '/tipeB1.jpg',
    harvest: 'Januari',
    price : '50.000 - 60.000',
    description: 'bawang 2 bawang 2 bawang 2 bawang 2 bawang 2 bawang 2 bawang 2 bawang 2'
  },
  {
    id: 3,
    title: 'bawang 3',
    type : 'Tajuk (Thailand Nganjuk)',
    href: '/bawang3',
    image: '/tipeC1.jpg',
    harvest: 'Januari',
    price : '50.000 - 60.000',
    description: 'bawang 3 bawang 3 bawang 3 bawang 3 bawang 3 bawang 3 bawang 3 bawang 3'
  },
] 