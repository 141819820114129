export const Option = [
  {
    name : 'Beranda'
  },
  {
    name : 'Tentang Kami'
  },
  {
    name : 'Produk'
  },
] 