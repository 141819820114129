import React from 'react'
import Carousel from "./Micro/Carousel"

export default function Product() {
  return (
    <div id="Produk" className="mb-5">
      <Carousel/>
      <div className='swiper-pagination'></div>
    </div>
  )
}
